import React, { useState, useEffect } from 'react';
import { View, Text, Image, StyleSheet, Alert, Modal, Pressable } from 'react-native';
import axios from 'axios';
import { FAB } from 'react-native-paper';
import { TouchableHighlight } from 'react-native-gesture-handler';
import GlobalStyle from '../themes/global';
const urlDataPublic = "https://vifsystems.oktopod.dev/public/";

const Item = ({ navigation, style, item }) => {
  return (
    <View style={{flex:1, width:"100%"}}>
      <Image style={styles.mainImg} source={{ uri: urlDataPublic + "products/" + item.file }}  />
      <View style={{flex:0.5, padding:20, width:"100%"}}>
        <Text style={styles.titleMain}>{item.name}</Text>
        <Text style={styles.p}>{item.description}</Text>
        <Text style={styles.p}>{item.flavour}</Text>
        <Text style={styles.p}>{item.property}</Text>
        <Text style={{width:200}}>Amertume</Text><Text>● ○ ○ ○ ○ </Text>
        <Text style={{width:200}}>Acidité</Text><Text>● ● ● ○ ○ </Text>
        <Text style={{width:200}}>Humidité</Text><Text>● ● ● ● ○ </Text>
        <Text style={{width:200}}>Température</Text><Text>● ● ● ● ○ </Text>
      </View>
    </View>
  );
};

export const ProductsContent =  ({ navigation, style }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentitem, setCurrentItem] = useState({});
  let [products, setProducts] = React.useState([]);

  // Initialisation des DATAS provenant du STORE
  async function initData(){
    let distProducts = await axios.get( urlDataPublic + 'data/products.js' );
    console.log(distProducts.data);
    setProducts(distProducts.data);
  }
  useEffect(()=>{initData()},[])

  // Initialisation de l'item courrant
  function initCurrentItem(item){
    setCurrentItem(item);
    setModalVisible(!modalVisible);

  }
  return (
    <View style={style}>
    <Text style={styles.title}>Catalogue Végétale</Text>
    <View style={{display: "flex", flexDirection:'column', flexWrap:'wrap'}}>
    {
    products.map((item) =>
    <TouchableHighlight 
      style={GlobalStyle.card} 
      key={item.id}
      activeOpacity={0.6}
      underlayColor="#DDDDDD"
      onPress={() => initCurrentItem(item)}
      >
      <View style={{height:200, width:"100%"}}>
        <Image style={styles.minImg}source={{ uri: urlDataPublic + "products/" + item.file }}  />
        <Text style={styles.title}>{item.name}</Text>
      </View>
    </TouchableHighlight>
    )
    }
    </View>
    <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <Item item={currentitem} style={styles.contentView} />
          <FAB
                style={styles.fab}
                small
                icon="close"
                onPress={() => setModalVisible(!modalVisible)}
                />
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: '#FFFFFFDD',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  contentView: {
  },
  container: {
    justifyContent: 'flex-start'
  },
  group: {
    flexWrap: "wrap",
    alignItems: 'left',
    justifyContent: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: "wrap",
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold',
    margin: 20,
    position:'absolute',
    bottom: 0,
  },
  titleMain: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },
  p: {
    fontSize: 12,
    fontWeight: 'bold',
    margin: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  minImg: {
    width: "100%",
    height: "100%",
  },
  mainImg: {
    flex:0.5,
    width:"100%",
    height: "100%",
  },
  fab: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor:'#FFF',
  },
});
