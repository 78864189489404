import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Card } from '../components/ux/Card';

export const FanContent = ({ navigation, style }) => {
  return (
    <View style={style}>
    <Text style={styles.title}>Ventilation</Text>
    <View style={styles.group}>
        <Text style={styles.subtitle}>Entrée</Text>
        <View style={styles.row}>
        <Card title="Ventilateur 0" icon="pot" slideState={true} path="fans/circulation/0" type="POST" />
        <Card title="Clapet 0" icon="pot"  switchState={true} path="" type="POST"/>
        </View>
        <View style={styles.row}>
        <Card title="Ventilateur 1" icon="pot" slideState={true}  path="/fans/circulation/1" type="POST" />
        <Card title="Clapet 0" icon="pot"  switchState={true} />
        </View>
    </View>
    <View style={styles.group}>
        <Text style={styles.subtitle}>Sortie</Text>
        <View style={styles.row}>
        <Card title="Ventilateur 1" icon="pot" />
        <Card title="Clapet 1" icon="pot"  />
        </View>
        <View style={styles.row}>
        <Card title="Ventilateur 2" icon="pot" />
        <Card title="Clapet 2" icon="pot"  />
        </View>
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start'
  },
  group: {
    flexWrap: "wrap",
    justifyContent: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: "wrap",
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 20,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});
