import React from 'react';
import { View, Text, Image, StyleSheet, StatusBar, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
const ScenarioScreen = ({ navigation }) => {


  const { colors } = useTheme();
  const size = 30;

  const theme = useTheme();

  return (
      <View style={styles.container}>
          <Text>Ecran des Scénarios à venir ...</Text>
      </View>
  );
};

export default ScenarioScreen;

const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent:'center',
    alignItems:'center',

  }
});
