import * as React from 'react';
import { View, StyleSheet, Text, Image, Switch } from 'react-native';
import Slider from '@react-native-community/slider';
import Api from '../../services/api';

import icons from './../../../assets/index';

export function Card({title,icon, switchState, slideState, path, data, value, unit}){



  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [slider, setSlider] = React.useState(0);
  const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);

  async function onSlideChange(value){
    console.log(value);
    const res = await Api.postData(path, data);
    console.log(res.data);
    setSlider(slider);
  }

      return (
        <View style={styles.container}>
         <View style={styles.icons}><Image style={{height:30, width:30}} source={icons[icon]} /></View>
         <View style={styles.content}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.value}>{value} {unit}</Text>
          {switchState &&  <Switch value={isSwitchOn} onValueChange={onToggleSwitch} /> }
          {slideState && 
            <Slider style={{padding:10, width:100}}
              minimumValue={0}
              maximumValue={100}
              onSlidingComplete={onSlideChange}
              onVa
            />
          }
          </View>
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center', 
      flexDirection: 'row',
      margin:5,
      padding:10,
      backgroundColor: 'white',
      shadowColor: '#00000010',
      shadowOffset: { width: 0, height: 0 },
      shadowRadius:10,
      borderRadius:5,
    },
    icons: {
      backgroundColor:'#89D35A',
      padding:10,
      borderRadius:50,
    },
    title: {
      fontFamily:"Arial",
      fontWeight: 'bold',
      fontSize: 10, 
    },
    content: {
      paddingLeft:10,
    },
    value: {
      fontSize: 18, 
    },
  });