import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

export function Stats({data}){

    return (
        <View style={styles.container}>
            
            {data.map(function(element){
                return <View style={[styles.bar,{height: element}]}></View>;
                })}
            
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      flex: 1, 
      flexDirection: 'row', 
      alignItems: 'flex-end', 
      minHeight: 100,
      minWidth: 300,
      margin:10,
      justifyContent: 'center',
    },
    bar: {
        width:20,
        margin:5,
        backgroundColor:'#999',
        borderRadius:10,
    }
  });