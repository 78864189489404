import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Logo } from '../ux';
import GlobalStyle from '../../themes/global';

export const Header = ({ navigation }) => (
  <View style={[styles.header, GlobalStyle.shadow]}>
  <Logo fill="#89D35A" width={150} />
  <Icon.Button name="ios-menu" size={30} backgroundColor="transparent" color="#89D35A"   onPress={() => navigation.openDrawer()}></Icon.Button>
</View>
);

const styles = StyleSheet.create({
    header: {
      zIndex:1,
      flexDirection:'row',
      justifyContent:'space-between',
      padding: 10,
      marginBottom:10,
      backgroundColor:'white',
      borderBottomRightRadius:50
    },
    icon: {
      marginTop: -5,
    },
  });
  