import axios from 'axios'
import AsyncStorage from '@react-native-community/async-storage';

//const urlApi = "http://localhost:18512/";
const urlApi = "http://localhost:3000/";

function apiRest(path, data, method) {
    return axios({
        'method': method,
        'url': urlApi + path,
        'headers': {
            'content-type': 'application/json',
        //    'Authorization': "Bearer " + token,
        },
        'data': data,
    })
}
export default {

    login: (username, password) =>
        axios({
            'method': 'POST',
            'url': urlApi + '/login_check',
            'headers': {
                'content-type': 'application/json',
            },
            'data': {
                'username': username,
                'password': password,
            },
        }),
    getData: (path, data) => {
        return apiRest(path, data, 'GET');
    },
    postData: (path, data) => {
        return apiRest(path, data, 'POST');
    }
}