import React from 'react';
import { View, Text, Image, StyleSheet, StatusBar, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { LevelContent } from '../sections/LevelContent';

const HomeScreen = ({ navigation }) => {


  const { colors } = useTheme();
  const size = 30;

  const theme = useTheme();

  return (
    <ScrollView>
      <View style={styles.container}>
        <LevelContent  style={{flex:1, backgroundColor:"#FAFAFA"}} />
      </View>
    </ScrollView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  }
});
