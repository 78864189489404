export let Users = [
    {
        id: 1, 
        email: 'admin@oktopod.dev',
        username: 'admin', 
        password: 'admin', 
        userToken: 'token123'
    },
    {
        id: 2, 
        email: 'demo@oktopod.dev',
        username: 'demo', 
        password: 'demo', 
        userToken: 'testtoken'
    },
    {
        id: 3, 
        email: 'antony@oktopod.dev',
        username: 'antony', 
        password: 'antony', 
        userToken: 'token12345'
    },
];