import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

const StatsScreen = () => {
    return (
      <View style={styles.container}>        
        <Image source={require('../../assets/linecharts.png')} style={styles.charts} resizeMode="contain" />
        <Image source={require('../../assets/barcharts.png')} style={styles.charts} resizeMode="contain" /> 
      </View>
    );
};

export default StatsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    justifyContent:'space-evenly',
    alignItems: 'center', 
    
  },
  charts: {
    height: 200, 
    width: "100%",
  },
});
