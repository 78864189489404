import React from 'react';
import { View, Text, Image, StyleSheet, StatusBar, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { FanContent } from '../sections/FanContent';
import { Header } from '../components/header/Header';

const HomeScreen = ({ navigation }) => {


  const { colors } = useTheme();
  const size = 30;

  const theme = useTheme();

  return (
    <>
    <Header navigation={navigation} />
    <ScrollView>
      <View style={styles.container}>
        <FanContent style={{flex:1, backgroundColor:"white"}} />
      </View>
    </ScrollView>
    </>

  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  }
});
