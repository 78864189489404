import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Card } from '../components/ux/Card';
import Api from '../services/api';

export const LevelContent =  ({ navigation, style }) => {

  let [data, setData] = React.useState(
    async () => {
      //const res = await Api.getData('api/bank/random_bank');
      //console.log(res.data);
      //setData(res);
     }
   );

  return (
    <View style={style}>
    <View style={styles.group}>
        <View style={styles.row}>
        <Card title="Température de l'air" value="28" icon="pot" unit="°c" />
        <Card title="Humidité de l'air" value="20" icon="pot" unit="%"  />
        </View>
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start'
  },
  group: {
    flexWrap: "wrap",
    alignItems: 'left',
    justifyContent: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: "wrap",
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 20,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});
