import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    card: {
      alignItems: 'center', 
      flexDirection: 'row',
      borderRadius:10,
      overflow: 'hidden',
      marginTop:20,
      marginLeft:20,
      marginRight:20,
      shadowColor: '#00000040',
      shadowOffset: { width: 0, height: 0 },
      shadowRadius:10,
      borderRadius:5,
    },
    shadow: {
      shadowColor: '#00000030',
      shadowOffset: { width: 0, height: 0 },
      shadowRadius:10,
      borderRadius:5,
    },
    icons: {
      backgroundColor:'#89D35A',
      padding:10,
      borderRadius:50,
    },
    title: {
      fontFamily:"Arial",
      fontWeight: 'bold',
      fontSize: 10, 
    },
    content: {
      paddingLeft:10,
    },
    value: {
      fontSize: 18, 
    },
  });
  export default styles;