const icons = {
    "apple-1" : require('./icons/apple-1.png'),
    "apple" : require('./icons/apple.png'),
    "barn" : require('./icons/barn.png'),
    "calculation" : require('./icons/calculation.png'),
    "condition" : require('./icons/condition.png'),
    "diagram" : require('./icons/diagram.png'),
    "drone" : require('./icons/drone.png'),
    "drops" : require('./icons/drops.png'),
    "farmer" : require('./icons/farmer.png'),
    "farming" : require('./icons/farming.png'),
    "fertilizer" : require('./icons/fertilizer.png'),
    "field-1" : require('./icons/field-1.png'),
    "field" : require('./icons/field.png'),
    "gardening" : require('./icons/gardening.png'),
    "greenhouse-1" : require('./icons/greenhouse-1.png'),
    "greenhouse-2" : require('./icons/greenhouse-2.png'),
    "growth-1" : require('./icons/growth-1.png'),
    "growth" : require('./icons/growth.png'),
    "harvest" : require('./icons/harvest.png'),
    "hothouse" : require('./icons/hothouse.png'),
    "humidity" : require('./icons/humidity.png'),
    "info" : require('./icons/info.png'),
    "irrigation-1" : require('./icons/irrigation-1.png'),
    "leaf-1" : require('./icons/leaf-1.png'),
    "leaf" : require('./icons/leaf.png'),
    "location-1" : require('./icons/location-1.png'),
    "location" : require('./icons/location.png'),
    "mobile-phone" : require('./icons/mobile-phone.png'),
    "nature" : require('./icons/nature.png'),
    "npk-1" : require('./icons/npk-1.png'),
    "npk-2" : require('./icons/npk-2.png'),
    "npk" : require('./icons/npk.png'),
    "pest" : require('./icons/pest.png'),
    "ph" : require('./icons/ph.png'),
    "plant" : require('./icons/plant.png'),
    "pot-1" : require('./icons/pot-1.png'),
    "pot" : require('./icons/pot.png'),
    "profit" : require('./icons/profit.png'),
    "rading" : require('./icons/rading.png'),
    "science" : require('./icons/science.png'),
    "search" : require('./icons/search.png'),
    "soil" : require('./icons/soil.png'),
    "solar-panel" : require('./icons/solar-panel.png'),
    "sunlight" : require('./icons/sunlight.png'),
    "tractor" : require('./icons/tractor.png'),
    "turbine" : require('./icons/turbine.png'),
    "watering" : require('./icons/watering.png'),
    "wifi" : require('./icons/wifi.png')
};
export default icons;