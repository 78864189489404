import React from 'react';
import { View, Text, Image, StyleSheet, StatusBar, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { Card } from '../components/ux/Card';
import { Stats } from '../components/ux/Stats';
import { FanContent } from '../sections/FanContent';
import { ProductsContent } from '../sections/ProductsContent';
import { Header } from '../components/header/Header';

const ProductsScreen = ({ navigation }) => {


  const { colors } = useTheme();
  const size = 30;
  const theme = useTheme();

  return (
    <>
    <Header navigation={navigation} />
    <ScrollView style={{marginTop:-20, paddingTop:20}}>
      <View style={styles.container}>
        <ProductsContent style={{flex:1}} />
      </View>
    </ScrollView>
    </>
  );
};

export default ProductsScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  }
});
