import React from 'react';
import { 
    View, 
    Text, 
    TouchableOpacity, 
    Dimensions,
    StyleSheet,
    StatusBar,
    Image
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import { LinearGradient } from 'expo-linear-gradient';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { useTheme } from '@react-navigation/native';

const SplashScreen = ({navigation}) => {
    const { colors } = useTheme();

    return (
      <View style={styles.container}>
          <StatusBar backgroundColor='#89D35A' barStyle="light-content"/>
        <View style={styles.header}>
            <Animatable.Image 
                animation="zoomIn"
                duraton="1500"
            source={require('../../assets/logo-vif.png')}
            style={styles.logo}
            resizeMode="stretch"
            />
        </View>
        <Animatable.View 
            style={[styles.footer, {
                backgroundColor: colors.background
            }]}
            animation="zoomIn"
        >
            <Text style={[styles.title, {
                color: colors.text
            }]}>Armoire hydroponique </Text>
            <Text style={styles.text}>avec identification (admin/admin) </Text>
            <View style={styles.button}>
            <TouchableOpacity onPress={()=>navigation.navigate('SignInScreen')}>
                <LinearGradient
                    colors={['#89D35A', '#89D35A']}
                    style={styles.signIn}
                >
                    <Text style={styles.textSign}>Démarrer</Text>
                    <MaterialIcons 
                        name="navigate-next"
                        color="#fff"
                        size={20}
                    />
                </LinearGradient>
            </TouchableOpacity>
            </View>
            <Text>by Oktopod</Text>

        </Animatable.View>
      </View>
    );
};

export default SplashScreen;

const {height} = Dimensions.get("screen");
const height_logo = height * 0.1;

const styles = StyleSheet.create({
  container: {
    flex: 1, 
    backgroundColor: '#89D35A',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
      justifyContent: 'center',
      alignItems: 'center'
  },
  footer: {
      backgroundColor: '#fff',
      borderRadius: 10,
      padding: 30,
      margin: 30,
  },
  logo: {
      width: height_logo*3,
      height: height_logo
  },
  title: {
      color: '#05375a',
      fontSize: 30,
      fontWeight: 'bold'
  },
  text: {
      color: 'grey',
      marginTop:5
  },
  button: {
      alignItems: 'flex-end',
      marginTop: 30
  },
  signIn: {
      width: 150,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      flexDirection: 'row'
  },
  textSign: {
      color: 'white',
      fontWeight: 'bold'
  }
});

