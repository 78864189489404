import React from 'react';
import { View, StyleSheet } from 'react-native';
import {
    useTheme,
    Avatar,
    Title,
    Caption,
    Paragraph,
    Drawer,
    Text,
    TouchableRipple,
    Switch
} from 'react-native-paper';
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer';
import { Logo } from '../components/ux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import{ AuthContext } from '../components/context';

export function DrawerContent(props) {

    const paperTheme = useTheme();

    const { signOut, toggleTheme } = React.useContext(AuthContext);

    return(
        <View style={{flex:1}}>
            <DrawerContentScrollView {...props}>
                <View style={styles.drawerContent}>
                    <View style={styles.userInfoSection}>
                        <View style={{flexDirection:'row',padding: 20, marginRight:20}}>
                            <Logo fill="#89D35A" />
                        </View>
                    </View>
                    <View style={styles.drawerSection}>
                    <Drawer.Section>
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Générale"
                            onPress={() => {props.navigation.navigate('HomeScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Ventilation"
                            onPress={() => {props.navigation.navigate('FanScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Etages"
                            onPress={() => {props.navigation.navigate('LevelScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Scénario"
                            onPress={() => {props.navigation.navigate('ScenarioScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Catalogue Végétale"
                            onPress={() => {props.navigation.navigate('ProductsScreen')}}
                        />
                         { /* <DrawerItem 
                            style={styles.drawerItem}
                            label="Environnement"
                            onPress={() => {props.navigation.navigate('EnvironmentScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Humidificateur"
                            onPress={() => {props.navigation.navigate('HumidifierScreen')}}
                        />
                        <DrawerItem 
                            style={styles.drawerItem}
                            label="Support"
                            onPress={() => {props.navigation.navigate('SupportScreen')}}
                        />*/ }
                    </Drawer.Section>
                    </View>
                    { /* <Drawer.Section title="Preferences">
                         <TouchableRipple onPress={() => {toggleTheme()}}>
                            <View style={styles.preference}>
                                <Text>Dark Theme</Text>
                                <View pointerEvents="none">
                                    <Switch value={paperTheme.dark}/>
                                </View>
                            </View>
                        </TouchableRipple>
                    </Drawer.Section>
                    */ }
                </View>
            </DrawerContentScrollView>
            <Drawer.Section style={styles.bottomDrawerSection}>
                <DrawerItem 
                    icon={({color, size}) => (
                        <Icon 
                        name="exit-to-app" 
                        color={color}
                        size={size}
                        />
                    )}
                    label="Se déconnecter"
                    onPress={() => {signOut()}}
                />
            </Drawer.Section>
        </View>
    );
}

const styles = StyleSheet.create({
    drawerContent: {
      flex: 1,
    },
    userInfoSection: {
      paddingLeft: 20,
    },
    title: {
      fontSize: 16,
      marginTop: 3,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 14,
      lineHeight: 14,
    },
    row: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    section: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 15,
    },
    paragraph: {
      fontWeight: 'bold',
      marginRight: 3,
    },
    drawerSection: {
      marginTop: 15
    },
    drawerItem: {
      marginTop: 15,
      backgroundColor:'#89D35A0A',
      padding: 5,
      textTransform: 'uppercase'
    },
    bottomDrawerSection: {
        marginBottom: 15,
        borderTopColor: '#f4f4f4',
        borderTopWidth: 1
    },
    preference: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
  });
