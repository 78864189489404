import React from 'react';

import { useWindowDimensions, View, StyleSheet } from 'react-native';

import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Entypo from 'react-native-vector-icons/Entypo';

// Drawer Navigator
import SupportScreen from '../screens/SupportScreen';
import SettingsScreen from '../screens/SettingsScreen';

// Stack Navigator
import SplashScreen from '../screens/SplashScreen';
import SignInScreen from '../screens/SignInScreen';
import SignUpScreen from '../screens/SignUpScreen';

// Main Tab Navigator
import HomeScreen from '../screens/HomeScreen';
import LevelScreen from '../screens/LevelScreen';
import FanScreen from '../screens/FanScreen';
import ScenarioScreen from '../screens/ScenarioScreen';
import ProductsScreen from '../screens/ProductsScreen';
import StatsScreen from '../screens/StatsScreen';

import { DrawerContent } from '../sections/DrawerContent';

const Drawer = createDrawerNavigator();
const Tab = createMaterialBottomTabNavigator();
const RootStack = createStackNavigator();
const HomeStack = createStackNavigator();

export const AppNavigation = ({ navigation }) => {
    const dimensions = useWindowDimensions();
    return (
        <Drawer.Navigator
            drawerContent={props => <DrawerContent {...props} />}
            drawerType={dimensions.width >= 768 ? 'permanent' : 'front'}
            initialRouteName="TabNavigation"
        >
            <Drawer.Screen name="TabNavigation" component={TabNavigation} 
         />
            <Drawer.Screen name="HomeScreen" component={HomeScreen} />
            <Drawer.Screen name="LevelScreen" component={LevelScreen} />
            <Drawer.Screen name="FanScreen" component={FanScreen} />
            <Drawer.Screen name="ScenarioScreen" component={ScenarioScreen} />
            <Drawer.Screen name="ProductsScreen" component={ProductsScreen} />
        </Drawer.Navigator>
    )
};

export const AuthNavigation = ({ navigation }) => (
    <RootStack.Navigator headerMode='none'>
        <RootStack.Screen name="SplashScreen" component={SplashScreen} />
        <RootStack.Screen name="SignInScreen" component={SignInScreen} />
        <RootStack.Screen name="SignUpScreen" component={SignUpScreen} />
    </RootStack.Navigator>
);

export const TabNavigation = ({ navigation }) => (
    <Tab.Navigator
        initialRouteName="home"
        barStyle={{ backgroundColor: '#FFF' }}
    >
        <Tab.Screen name="home" component={HomeScreen}
        options={{
            tabBarLabel: 'Home',
            tabBarIcon: ({ color }) => (
                <Ionicons name="home" color={color} size={26} style={styles.icon} />
                ),
          }}
        />
        <Tab.Screen name="products" component={ProductsScreen}
        
        options={{
            title: 'Général',
            tabBarLabel: 'Catalogue',
            tabBarIcon: ({ color }) => (
                <Entypo name="open-book" color={color} size={26} style={styles.icon} />
                )
          }}
        />
        <Tab.Screen name="stats" component={StatsScreen}
        options={{
            tabBarLabel: 'Stats',
            tabBarIcon: ({ color }) => (
              <Ionicons name="stats-chart" color={color} size={26} style={styles.icon} />
            ),
          }}
        />
        <Tab.Screen name="settings" component={SettingsScreen}
        options={{
            tabBarLabel: 'Paramètres',
            tabBarIcon: ({ color }) => (
              <Ionicons name="settings-outline" color={color} size={26} style={styles.icon} />
            ),
          }}
        /> 
    </Tab.Navigator>
);


const styles = StyleSheet.create({
    icon: {
      marginTop: -5,
    },
  });
  