import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { LevelContent } from '../sections/LevelContent';
import { HomeContent } from '../sections/HomeContent';
import { Header } from '../components/header/Header';
import { InteractivityImg } from '../components/interactivityImg/interactivityImg';

const HomeScreen = ({ navigation }) => {


  const { colors } = useTheme();
  const size = 30;

  const theme = useTheme();

  return (
    <>
    <Header navigation={navigation} />
    <ScrollView style={{marginTop:-20, paddingTop:20}}>
      <View style={styles.container}>
        <InteractivityImg />
        <LevelContent />
      </View>
    </ScrollView>
    </>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems:'center',
  },
  charts: {
    height: '200',
    width:'100%',
  },
});
