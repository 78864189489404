import React, {useState} from 'react';
import { View, StyleSheet, TouchableOpacity, ImageBackground, Switch, Text} from 'react-native';



export function InteractivityImg(){
  const [colorA, setColorA] = React.useState(true);
  const [colorB, setColorB] = React.useState(true);
  const [colorC, setColorC] = React.useState(true);
  const [colorD, setColorD] = React.useState(true);

  function handlerPressA(){ (colorA) ? setColorA(false) : setColorA(true);}
  function handlerPressB(){ (colorB) ? setColorB(false) : setColorB(true);}
  function handlerPressC(){ (colorC) ? setColorC(false) : setColorC(true);}
  function handlerPressD(){ (colorD) ? setColorD(false) : setColorD(true);}
  return (
    <View style={styles.container}>
      <ImageBackground source={require('../../../assets/armoire-vif.jpg')} style={styles.imgArmoire}> 
      <TouchableOpacity onPress={handlerPressA} style={[styles.touchable, styles.touchA,  {backgroundColor: colorA ? "orange" : "green" }]}>
      </TouchableOpacity>   
      <TouchableOpacity onPress={handlerPressB} style={[styles.touchable, styles.touchB,  {backgroundColor: colorB ? "orange" : "green" }]}>
      </TouchableOpacity>  
      <TouchableOpacity onPress={handlerPressC} style={[styles.touchable, styles.touchC,  {backgroundColor: colorC ? "orange" : "green" }]}>
      </TouchableOpacity>  
      <TouchableOpacity onPress={handlerPressD} style={[styles.touchable, styles.touchD,  {backgroundColor: colorD ? "orange" : "green" }]}>
      </TouchableOpacity>  
      </ImageBackground>

      <View style={styles.btauto}>
        <Text style={styles.title}>Mode auto</Text>
        <View style={styles.group}>

        <TouchableOpacity onPress={handlerPressA} style={styles.row}><Text>Arrosage </Text><Switch value={colorA} /></TouchableOpacity>  
        <TouchableOpacity onPress={handlerPressB} style={styles.row}><Text>Lumière </Text><Switch value={colorB}  /></TouchableOpacity>  
        <TouchableOpacity onPress={handlerPressC} style={styles.row}><Text>Ventilation </Text><Switch value={colorC} /></TouchableOpacity>  
        <TouchableOpacity onPress={handlerPressD} style={styles.row}><Text>Humidification </Text><Switch value={colorD} /></TouchableOpacity>  
      </View>
      </View>
    </View>    
);
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    imgArmoire: {
      width: 200,
      height: 330,
      justifyContent: "center"
    },
    touchable: {
    position:'absolute',
    width:30,
    height:30,
    borderRadius:50,
    },
    touchA: {
    top: 50,
    left: 140
    },
    touchB: {
    top: 100,
    left: 80
    },
    touchC: {
    top: 193,
    left: 140,
    },
    touchD: {
    top: 260,
    left: 22,
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: "wrap",
      justifyContent: 'space-between',
      padding:5,
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 20,
      color:"#89D35A"
    },
    btauto: {
      marginTop:30,
      paddingBottom:20,
      justifyContent: 'flex-start',
      borderColor: '#89D35A',
      borderWidth: 3,
      borderTopLeftRadius: 50,
      borderBottomRightRadius: 50,
    },
  });
  