import React from 'react';
import { View, Text, StyleSheet, Switch } from 'react-native';
import { Card } from '../components/ux/Card';

export const HomeContent = ({ navigation, style }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Mode auto</Text>
      <View style={styles.group}>
          <View style={styles.row}><Text>Arrosage </Text><Switch value={true}  /></View>
          <View style={styles.row}><Text>Lumière </Text><Switch value={true}  /></View>
          <View style={styles.row}><Text>Ventilation </Text><Switch value={true}  /></View>
          <View style={styles.row}><Text>Humidification </Text><Switch value={true}  /></View>
          <View style={styles.row}>
      </View>
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    borderColor: '#89D35A',
    borderWidth: 3,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  group: {
    flexWrap: "wrap",
    justifyContent: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: "wrap",
    justifyContent: 'space-between',
    padding:5,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 20,
    color:"#89D35A"
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});
